import { SAGA_ACTIONS } from 'src/_config'
import { GetEventList, GetEventGameList, GetEventGameDetails, GetUpcomingEventList, JoinEventGame, PickRider, LeaderBoard, TvImageDetails, SaveVote, GameSettingsReq, SaveColorReq } from 'src/_common/interfaces/ApiReqRes'
import { useApiCall } from '../common/appApiCallHook'

export function useEventApi() {

    const callApi = useApiCall()

    // const eventList = (data: GetEventList, onSuccess: Function, onError: Function) => {
    //     callApi(SAGA_ACTIONS.EVENT.GET_EVENT_LIST, data, onSuccess, onError);
    // }

    // const eventGameList = (data: GetEventGameList, onSuccess: Function, onError: Function) => {
    //     callApi(SAGA_ACTIONS.EVENT.GET_EVENT_GAME, data, onSuccess, onError);
    // }

    // const eventGameDetails = (data: GetEventGameDetails, onSuccess: Function, onError: Function) => {
    //     callApi(SAGA_ACTIONS.EVENT.GET_EVENT_GAME_DETAILS, data, onSuccess, onError);
    // }

    // const upcomingEventList = (data: GetUpcomingEventList, onSuccess: Function, onError: Function) => {
    //     callApi(SAGA_ACTIONS.EVENT.GET_UPCOMING_EVENT, data, onSuccess, onError);
    // }

    // const pickRider = (data: PickRider, onSuccess: Function, onError: Function) => {
    //     callApi(SAGA_ACTIONS.EVENT.PICK_RIDER, data, onSuccess, onError);
    // }

    // const viewLeaderBoard = (data: LeaderBoard, onSuccess: Function, onError: Function) => {
    //     callApi(SAGA_ACTIONS.EVENT.LEADERBOARD, data, onSuccess, onError);
    // }

    // const joinEventGame = (data: JoinEventGame, onSuccess: Function, onError: Function) => {
    //     callApi(SAGA_ACTIONS.EVENT.JOIN_EVENT_GAME, data, onSuccess, onError);
    // }

    const tvImageList = (data: TvImageDetails, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.TV_IMAGE.DETAILS, data, onSuccess, onError, false);
    }

    // const saveVote = (data: SaveVote, onSuccess: Function, onError: Function) => {
    //     callApi(SAGA_ACTIONS.EVENT.SAVE_VOTE, data, onSuccess, onError);
    // }

    const getGameSettings = (data: GameSettingsReq, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.EVENT.GET_GAME_SETTINGS, data, onSuccess, onError,false);
    }

    const saveColor = (data: SaveColorReq, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.EVENT.SAVE_COLOR, data, onSuccess, onError,false);
    }
    
    return {
        // callGetEventList: eventList,
        // callGetUpcomingEventList: upcomingEventList,
        // callGetEventGameList: eventGameList,
        // callGetEventGameDetails: eventGameDetails,
        // callPickRider: pickRider,
        // callViewLeaderBoard: viewLeaderBoard,
        callTvImageDetails: tvImageList,
        // callJoinEventGame: joinEventGame,
        // callSaveVote: saveVote,
        callGetGameSettings: getGameSettings,
        callSaveColor: saveColor,
    }
}