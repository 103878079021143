import { useDispatch } from 'react-redux'
import { ACTIONS } from '../../../../_config'

export function useAppUserAction() {

  const dispatch = useDispatch()

  const logout = () => {
    dispatch({
      type: ACTIONS.USER.LOGOUT,
    })
  }

  const socketInstanceContainer = (socketInstance: any) => {
    dispatch({
      type: ACTIONS.USER.SOCKET_INSTANCE_CONTAINER,
      payload: socketInstance
    })
  }

  const guestUserId = (guestUserId: any) => {
    dispatch({
      type: ACTIONS.USER.GUEST_USER_ID,
      payload: guestUserId
    })
  }


  return {
    logout,
    socketInstanceContainer,
    guestUserId,
  }
}