import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import useSocket from 'use-socket.io-client';
import { useAppGuestUserIdSelector, useAppUserDetailsSelector } from 'src/_common/hooks/selectors/userSelector';
import { useAppEvenListSelector, useAppRodeoEventLoginRequiredSelector } from 'src/_common/hooks/selectors/eventSelector';
import { CRYPTO_SECRET_KEY, INSIDERODEO_EVENT_STORAGE, paginate, PER_PAGE, SELECTED_EVENT_STORAGE, SOCKET_CHANNEL, SOCKET_URL, STORAGE, URLS } from 'src/_config';
import { useEventApi } from 'src/_common/hooks/actions/event/appEventApiHook';
import { toast } from 'react-toastify';
import clsx from 'clsx';
import { useAppEventAction } from 'src/_common/hooks/actions/event/appEventActionHook';
import { useAppUserAction } from 'src/_common/hooks/actions/user/appUserActionHook';
import { useUserApi } from 'src/_common/hooks/actions/user/appUserApiHook';
// import Pagination from 'react-js-pagination';
import NoSleep from 'nosleep.js';

const Cryptr = require('cryptr');
const cryptr = new Cryptr(CRYPTO_SECRET_KEY);
const queryString = require('query-string');

export default function RodeoGamePage() {

    const eventApi = useEventApi()
    const history = useHistory()
    const location = useLocation()
    const userAction = useAppUserAction()
    // const eventAction = useAppEventAction()
    const userDetails = useAppUserDetailsSelector()
    // const eventListSelector = useAppEvenListSelector()
    // const loginReq = useAppRodeoEventLoginRequiredSelector()
    // const [eventListData, setEventListData] = useState<any[]>([])
    // const [page, setPage] = useState<number>(1)
    // const [total, setTotal] = useState<number>(0)
    // const [isFetching, setIsFetching] = useState(false);
    // const [isPageChange, setIsPageChange] = useState<boolean>(false)
    const [colorListData, setColorListData] = useState<any>([])
    const [selectedColor, setSelectedColor] = useState<any>()
    const [activeMusicBeam, setActiveMusicBeam] =useState<boolean>(false)
    const [activeFlashBit, setActiveFlashBit] =useState<boolean>(false)
    const [activeFlashColor, setActiveFlashColor] =useState<boolean>(false)
    const [loginReq, setLoginReq] = useState<any>(0)
    const guestUserId = useAppGuestUserIdSelector()
    const [showColorPalette, setShowColorPalette] =useState<boolean>(false)
    const userApi = useUserApi()
    const [defaultFlashColor, setDefaultFlashColor] = useState<any>()

    const isInsideRodeoTokenExists = localStorage.getItem(INSIDERODEO_EVENT_STORAGE)
    const noSleep = new NoSleep();

    // const goToGameListingPage = (e: any, event: number) => {
    //     e.preventDefault();
    //     const eventId = cryptr.encrypt(event)
    //     localStorage.setItem(SELECTED_EVENT_STORAGE, eventId);
    //     // let lr = eventListSelector.list.find((x:any)=>x.id==event).login_required
    //     // eventAction.updateLoginRequired(lr)
    //     // console.log(loginReq)
    //     history.push(`/event/${eventId}`)
    // }

    // useEffect(() => {
    //     window.addEventListener('scroll', handleScroll);
    //     return () => window.removeEventListener('scroll', handleScroll);
    // }, []);

    // useEffect(() => {
    //     if (!isFetching) return;
    //     fetchMoreListItems();
    // }, [isFetching]);

    // Below function excute the scroll operation text show or hide
    // function handleScroll() {
    //     if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight || isFetching) return;
    //     if (eventListData.length === total) return; //Disable scrolling state if total event data is equal to total data
    //     setIsFetching(true);
    // }

    //Reset BgColor
    const resetBgColor = (c?:any) => {
        let x=document.getElementById('rodeo_body')
        if(x)
        {
            x.style.backgroundColor=c?c.color:""
        }
    }


    //Get game settings
    const getGameSettings = () => {
        var params:any = {

        }

        if(!userDetails)
        {
            if(guestUserId)
            {
                params['guest_user_id']=guestUserId
            }
        }
        eventApi.callGetGameSettings(params,(message: string, resp: any) => {
            if (resp) { 
                // console.log(resp)
                setColorListData(resp)
                if(resp.enable_music_beam && resp.enable_music_beam.enable_flash==1)
                {
                    setActiveFlashBit(true)
                }
                if(resp.enable_music_beam && resp.enable_music_beam.enable_beam==1)
                {
                    setActiveMusicBeam(true)
                    setSelectedColor(resp.active_music_beam_color)
                }
                else if(resp.selected_color)
                {
                    setSelectedColor(resp.selected_color.color)
                }
                else
                {
                    resetBgColor()
                }
                setLoginReq(resp.game_info.login_required)
            }
            else { }
        }, (message: string) => {
            toast.error(message)
        })
    }

    //Change backgroundcolor
    const changeColor = (c:any) => {
        // console.log(c)
        noSleep.enable();
        resetBgColor(c)
        if(!activeMusicBeam && !activeFlashBit)
        {
            // console.log(activeMusicBeam,"<< active >>",activeFlashBit)
            saveColor(c.id)
        }
    }

    //Call save color API
    const saveColor = (id:any) => {
        var params:any = {
            settings_id: id
        }
        if(!userDetails)
        {
            if(guestUserId)
            {
                params['guest_user_id']=guestUserId
            }
            // else if(guestId)
            // {
            //     params['guest_user_id']=guestId
            // }
        }
        eventApi.callSaveColor(params,(message: string, resp: any) => {
            if (resp) { 
                // console.log(resp)
                userAction.guestUserId(resp)
            }
            else { }
        }, (message: string) => {
            toast.error(message)
        })
    }

    const toggleColorPalette = () => {
        setShowColorPalette(!showColorPalette)
    }

    //On page scroll load more data
    // Event api call once again
    // const fetchMoreListItems = () => {
    //     setTimeout(() => {
    //         getEventList()
    //     }, 2000);
    // }

    //First Time call When component is load
    useEffect(() => {
        getGameSettings()
    }, [])

    // const handlePageChange = (inputPage: number) => {
    //     console.log("Page change call");
    //     if (page !== inputPage) {
    //         setIsPageChange(true)
    //         setPage(inputPage)
    //     }
    // }

    //Pagination page change api call
    // useEffect(() => {
    //     if (isPageChange) {
    //         getEventList()
    //     }
    // }, [page, perPage])

    useEffect(() => {
        const parsedHash = queryString.parse(location.hash);
        if(parsedHash&&parsedHash.userToken&&parsedHash.userToken!='null')
        {
        //   userAction.resetGuestUserId()
          localStorage.setItem(STORAGE, parsedHash.userToken);
          userApi.callGetMe((message: string, resp: any) => {
          }, (message: string, resp: any) => {
              history.push(URLS.HOME)
              userAction.logout()
          })
        }
        if (parsedHash && parsedHash.token) {
            localStorage.setItem(INSIDERODEO_EVENT_STORAGE, parsedHash.token)
        //     if (userDetails) {
        //         history.push(`/event/${localStorage.getItem(INSIDERODEO_EVENT_STORAGE)}`)
        //     } else {
        //         history.push(URLS.LOGIN)
        //     }
        // } else if (isInsideRodeoTokenExists) {
        //     history.push(`/event/${localStorage.getItem(INSIDERODEO_EVENT_STORAGE)}`)
        // } else {
            // history.push(URLS.HOME)
        }
    }, [])

    const [socket] = useSocket(SOCKET_URL, {
        autoConnect: false,
    });

    useEffect(() => {

        socket.connect();

        socket.on(SOCKET_CHANNEL.MUSIC_EVENT, (changeEventStatus: any) => {
            if(changeEventStatus)
            {
                // console.log(changeEventStatus)
                setActiveMusicBeam(true)
                // if(changeEventStatus.color)
                // {
                //     changeColor(changeEventStatus)
                // }
                // else
                // {
                //     resetBgColor()
                // }
                resetBgColor(changeEventStatus)
            }
        })

        socket.on(SOCKET_CHANNEL.ENABLE_DISABLE_MUSIC_BIT, (changeEventStatus: any) => {
            if(changeEventStatus)
            {
                console.log(changeEventStatus)
                if(changeEventStatus.enable_beam=="1")
                {
                    setShowColorPalette(false)
                    setActiveMusicBeam(true)
                }
                if(changeEventStatus.enable_beam=="0")
                {
                    setActiveMusicBeam(false)
                }
                if(changeEventStatus.enable_flash=="1")
                {
                    setShowColorPalette(false)
                    setActiveFlashBit(true)
                }
                if(changeEventStatus.enable_flash=="0")
                {
                    setActiveFlashBit(false)
                }
                getGameSettings()
            }
        })

        socket.on(SOCKET_CHANNEL.FLASH_EVENT, (changeEventStatus: any) => {
            if(changeEventStatus)
            {
                console.log(changeEventStatus)
                setShowColorPalette(false)
                setActiveFlashColor(true)
                resetBgColor(changeEventStatus[1])
                setDefaultFlashColor(changeEventStatus[0])
            }
        })

        socket.on(SOCKET_CHANNEL.COLOR_PALETTE, (changeEventStatus: any) => {
            if(changeEventStatus)
            {
                // console.log(changeEventStatus)
                getGameSettings()
            }
        })

        return () => {
            socket.disconnect()
        }
    },[socket])

    useEffect(() => {
        if(activeFlashColor&&defaultFlashColor)
        {
            setTimeout(()=>{
                console.log(selectedColor)
                setActiveFlashColor(false)
                // getGameSettings()
                // resetBgColor()
                resetBgColor(defaultFlashColor)
            },1000)
        }
    },[activeFlashColor,defaultFlashColor])

    useEffect(() => {
        if (userDetails || loginReq==0)
        {
            history.push(`${URLS.HOME}`)
        }
        else
        {
            history.push(`${URLS.LOGIN}`)
        }
    }, [loginReq,userDetails])

    useEffect(() => {
        if (selectedColor)
        {
            changeColor(selectedColor)
        }
    }, [selectedColor])

    return (
        <React.Fragment>
            {!activeMusicBeam && !activeFlashBit && 
            <><div id="hamburger" onClick={toggleColorPalette} className={showColorPalette?"show":""}>
                <div id="wrapper">
                    <span className="icon-bar" id="one"></span>
                    <span className="icon-bar" id="two">Click</span>
                    <span className="icon-bar" id="thr"></span>
                </div>
                {/* {showColorPalette?"":"Click"} */}
            </div>
            {/* <button className="click-btn" onClick={toggleColorPalette}>
                Click */}
                {/* <i className="fas fa-palette"></i> */}
            {/* </button> */}
            <div className={"row colorPalette"+(showColorPalette?" show":"")}>
                <h6 className="page-title text-left">
                    <span>Choose </span>Color</h6>
                <hr/>
                <div className="color-balls-wrap">
                    {
                        colorListData.color_list && colorListData.color_list.length ?
                        colorListData.color_list.map((color:any)=>(
                            <div className="col-2 colorList" key={color.id} style={{backgroundColor: color.color}} onClick={e=>changeColor(color)}> </div>
                        ))
                        : null
                    }
                </div>
            </div></>}
            {/* <ul className="list-style">
                {
                    eventListSelector && eventListSelector.list && eventListSelector.list.length ? eventListSelector.list.map((event: any) => (
                        <li key={event.id} className={
                            clsx({
                                "glow": event.currently_ongoing == 1
                            })
                        }>
                            <Link to="" onClick={(e) => goToGameListingPage(e, event.id)} title={event.title}
                                className="ripple-effect">
                                <span className="r-event-img">
                                    <img src={event.avatar && event.avatar.thumb ? event.avatar.thumb : 'images/no-image.png'} alt={event.title} />
                                </span>
                                <span className="r-event-info">
                                    <span className="d-inline-block r-event-title">{event.title}</span>
                                    <span className="d-block r-location">{event.location ? event.location : '...'}</span>
                                </span>
                            </Link>
                        </li>
                    )) :
                        <div className="no-text text-white text-center">
                            No events available
                        </div>
                }
            </ul> */}
            {/* {
                isFetching &&
                <div style={{ color: '#fce006' }}>
                    <p>Loading more events. Please wait... </p>
                </div>
            } */}
            {/* <div className="pagination-block">
                <Pagination
                    activePage={page}
                    itemsCountPerPage={PER_PAGE}
                    totalItemsCount={total}
                    pageRangeDisplayed={5}
                    itemClass="page-item"
                    linkClass="page-link"
                    onChange={handlePageChange}
                />
            </div> */}
            {/* <UpcomingRodeoEventComponent /> */}
        </React.Fragment >
    )
}
