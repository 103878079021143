import React, { useEffect } from 'react';
import Sidebar from 'src/components/common/Sidebar';
import RodeoGamePage from 'src/components/events/rodeoGame/rodeoGamePage';
import { useAppUserDetailsSelector } from 'src/_common/hooks/selectors/userSelector';
import { RODEO_PAGE_NAME } from 'src/_config';
import { APPLICATION_NAME } from 'src/_config';

export default function RodeoGameContainer() {

    const userDetails = useAppUserDetailsSelector()

    useEffect(() => {
        const prevTitle = document.title
        document.title = `${APPLICATION_NAME}`
        return () => {
            document.title = prevTitle
        }
    }, [])

    return (
        <React.Fragment>
            {/* {
                !userDetails ? */}
                    <div className="logo-wrap">
                        <img src="/images/logo.png" alt="Logo" className="medium-logo" />
                    </div> 
                    {/* : null */}
            {/* } */}
            <Sidebar page={RODEO_PAGE_NAME.EVENT_LIST} />
            <section className="main-container">
                <RodeoGamePage />
            </section>
        </React.Fragment>
    )
}
