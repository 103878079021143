import React, { useEffect, useState } from "react";
import Marquee from "react-fast-marquee";
import { useParams } from "react-router";
import { useEventApi } from "src/_common/hooks/actions/event/appEventApiHook";
import { useAppTvImageSelector } from "src/_common/hooks/selectors/eventSelector";
import { removeEmptyObjectKey, removeTrailingZero, SOCKET_CHANNEL, SOCKET_URL } from "src/_config";
import useSocket from 'use-socket.io-client';

export function RodeoTvImageComponent() {

    const eventApi = useEventApi()
    const tvImageSelector = useAppTvImageSelector()
    const { gameId } = useParams<any>();
    const [isInitialLoad, setIsInitialLoad] = useState<boolean>(false)
    const [isShowingAdsPromotion, setIsShowingAdsPromotion] = useState<boolean>(false)
    const [addPromotionImage, setAddPromotionImage] = useState<any>(null)
    const [addPromotionText, setAddPromotionText] = useState<any>("")
    const [defaultAdsPromotionTime, setDefaultAdsPromotionTime] = useState<number>(1000)
    const [statusMessage, setStatusMessage] = useState<any>(null)

    const getTvImageDetails = (screen1: string, type: any) => {
        const params = {
            screen1, type
        }
        eventApi.callTvImageDetails(removeEmptyObjectKey(params),
            (message: string, resp: any) => {
            }, (message: string) => {
                console.error(`Error at API Access => ${message}`)
            })
    }

    const resetCssStyle = () => {
        var element = document.getElementById('rodeo_body');
        if (element) {
            element.style.paddingTop = '0rem'
        }
    }

    //Socket Implementation start here
    const [socket] = useSocket(SOCKET_URL, {
        autoConnect: false,
    });

    useEffect(() => {
        socket.connect()
        socket.on(SOCKET_CHANNEL.TV_BOARD_IMAGE, (tvImageData: any) => {
            console.log(tvImageData)
            if (tvImageData.type == "landing_screen") {
                setIsInitialLoad(true)
                setStatusMessage(null)
            }
            // if (tvImageData.type == "rodeo_event") {
            //     getTvImageDetails(tvImageData.rodeo_event_id, "N")
            //     resetCssStyle()
            //     setIsShowingAdsPromotion(false)
            //     setIsInitialLoad(false)
            // } else if (tvImageData.type == "event") {
            //     getTvImageDetails(`${tvImageData.rodeo_event_id}||${tvImageData.event_id}`, "N")
            //     resetCssStyle()
            //     setIsShowingAdsPromotion(false)
            //     setIsInitialLoad(false)
            // } else {
            //     getTvImageDetails(gameId, null)
            //     setIsInitialLoad(true)
            // }
        });

        socket.on(SOCKET_CHANNEL.ADV_PROMOTION, (adsPromotionData: any) => {
            console.log(adsPromotionData)
            setAddPromotionImage(adsPromotionData.image)
            setAddPromotionText(adsPromotionData.text)
            resetCssStyle()
            setIsInitialLoad(false)

            setIsShowingAdsPromotion(true)
            setAddPromotionImage(adsPromotionData.image)
            setDefaultAdsPromotionTime(adsPromotionData.default_timeout)
        });

        socket.on(SOCKET_CHANNEL.STATUS_MESSAGE, (statusMessage: any) => {
            if(statusMessage)
            {
                console.log(statusMessage)
                setStatusMessage(statusMessage.status_text)
            }
        });



        return () => {
            socket.disconnect()
        }
    }, [socket])

    
    useEffect(() => {
        // resetCssStyle()
    }, [])

    useEffect(() => {
        // getTvImageDetails(gameId, null)
        setIsInitialLoad(true)
        var element = document.getElementById('rodeo_body');
        if (element) {
            element.style.paddingTop = '0px'
            element.style.marginBottom = '0px'
            element.style.backgroundColor = '#fff'
            element.style.backgroundImage = 'none'
        }
    }, [])

    return (
        <React.Fragment>
            {
                isInitialLoad ?
                    <div className="main-bg"
                        style={{
                            backgroundImage: `url("${'/images/tv/tv-screen-blank.jpg'}")`,
                            position: 'fixed',
                            width: '100%',
                            height: '100%',
                            backgroundSize: '100% 100%',
                            backgroundRepeat: 'no-repeat'
                        }}
                    >   
                        {
                            statusMessage
                            &&
                            <div className="status-bar-wrap">
                                {/* <Marquee direction='left' loop={0} pauseOnHover={true} speed={60}> */}
                                {statusMessage}
                                {/* </Marquee> */}
                            </div>
                        }
                    </div> :
                    <>
                        <div className="main-bg"
                            style={{ backgroundColor: '#000', position: 'fixed', width: '100%', height: '100%', left: 0, top: 0, zIndex: -1, overflowX: "hidden", margin: 0 }}
                        ></div>
                        <section className="container" style={{maxWidth: '100%',paddingTop: '40px'}}>
                        {/* <section className="container" style={{ maxWidth: '100%' }}> */}
                            {addPromotionText ?
                                <div className="logobar ">
                                    <div className="border-div" />
                                    <div className="border-div2" />
                                    <div className="logo_left_txt">
                                        {/* {
                                            `${tvImageSelector && tvImageSelector.rodeo_event_dtls ? tvImageSelector.rodeo_event_dtls.title : '--'}${tvImageSelector && tvImageSelector.event_dtls && tvImageSelector.event_dtls.rodeo_pick_event ? " :: " + tvImageSelector.event_dtls.rodeo_pick_event.title : ''}`
                                        } */}
                                        {
                                            addPromotionText
                                        }
                                    </div>
                                    <div className="logo_image"><img src="/images/tv/logo_.png" className="img-responsive" /></div>
                                </div>
                                :
                                <div className="logo_image_no_text"><img src="/images/tv/logo_.png" className="img-responsive" /></div>
                            }

                            <div className="heading_text">
                                {/* {
                                    tvImageSelector && tvImageSelector.event_dtls && tvImageSelector.event_dtls.sponsor ?
                                        `SPONSER BY ${tvImageSelector.event_dtls.sponsor.title}` : null
                                } */}
                            </div>
                            <div className="clearfix">
                                {/* {
                                    !isShowingAdsPromotion ?
                                        <ul className="tv-list">
                                            {
                                                tvImageSelector && tvImageSelector.riders && tvImageSelector.riders.length ?
                                                    tvImageSelector.riders.slice(0, tvImageSelector && tvImageSelector.limit ? tvImageSelector.limit : 10).map((riders: any) => (
                                                        <li key={riders.id}>
                                                            <div className="left_sec">
                                                                <div className="border-div"></div>
                                                                <div className="curve-bg">
                                                                    <div className="left_sec_l">{riders.name}</div>
                                                                    <div className="left_sec_r">{removeTrailingZero(riders.vote)}</div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    )) : <div className="text-center" style={{ color: '#fff' }}>
                                                        <p> No Result publish yet!</p>
                                                    </div>
                                            }
                                        </ul>
                                        : */}
                                        <div className="tv-ads-promotion">
                                            <img className="w-100" src={addPromotionImage} alt="image" />
                                        </div>
                                {/* } */}
                            </div>
                        </section> 
                    </>
            }


        </React.Fragment>
    )
}