import React, { useEffect, useState } from 'react'
import Marquee from "react-fast-marquee";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { useAppUserDetailsSelector, useAppUserTokenSelector } from 'src/_common/hooks/selectors/userSelector'
import { useAppEventStatusMessageSelector } from 'src/_common/hooks/selectors/eventSelector';
import { URLS, STORAGE, SELECTED_EVENT_STORAGE, INSIDERODEO_EVENT_STORAGE, detectDevice, IS_SHOWING_ADD_TO_HOME_SCREEN_IOS, CRYPTO_SECRET_KEY, SOCKET_CHANNEL, APPLICATION_GLOBAL_SLUG, SOCKET_URL } from 'src/_config';
import { useUserApi } from 'src/_common/hooks/actions/user/appUserApiHook';
import { useToaster } from 'src/_common/hooks/actions/common/appToasterHook';
import { useAppUserAction } from 'src/_common/hooks/actions/user/appUserActionHook';
import { RODEO_PAGE_NAME } from 'src/_config';
import useSocket from "use-socket.io-client";
import StatusModal from '../events/modal/statusModal';
import CouponModal from '../events/modal/couponModal';

const Cryptr = require('cryptr');
const cryptr = new Cryptr(CRYPTO_SECRET_KEY);

interface SideBarProps {
  page: string;
}

function Sidebar({ page }: SideBarProps) {
  const history = useHistory()
  const userDetails = useAppUserDetailsSelector()
  const statusMessageSelector = useAppEventStatusMessageSelector()
  const userAction = useAppUserAction()
  const userApi = useUserApi()
  const toast = useToaster()
  const location = useLocation()

  const isInsideRodeoTokenExists = localStorage.getItem(INSIDERODEO_EVENT_STORAGE)
  const [isShowIosPopup, setIsShowIosPopup] = useState<any>(localStorage.getItem(IS_SHOWING_ADD_TO_HOME_SCREEN_IOS) ? parseInt(localStorage.getItem(IS_SHOWING_ADD_TO_HOME_SCREEN_IOS) || '0') : 1)
  const [appRedirectData, setAppRedirectData] = useState<any>();
  const [showStatusModal, setShowStatusModal] = useState<boolean>(false);
  const [showCouponModal, setShowCouponModal] = useState<boolean>(false);
  const [couponData, setCouponData] = useState<any>();
  const userToken = useAppUserTokenSelector()

  // const [showHeaderMenu, setShowHeaderMenu] = useState<boolean>(true)

  const toogleSideBarOnOff = (status: boolean) => {
    var sidebarCollapseElement = document.getElementById("sidebarCollapse");
    var sidebarElement = document.getElementById("sidebar");
    var overlayElement = document.getElementById("overlay");
    if (status) {
      if (sidebarCollapseElement) {
        sidebarElement?.classList.add("active");
        overlayElement?.classList.add("active");
      }
    } else {
      if (sidebarCollapseElement) {
        sidebarElement?.classList.remove("active");
        overlayElement?.classList.remove("active");
      }
    }
  }

  // const toogleProfileMenuBarOnOff = () => {
  //   var navbarCollapseElement = document.getElementById("navbarCollapse");
  //   setShowHeaderMenu(!showHeaderMenu)
  //   if (showHeaderMenu) {
  //     if (navbarCollapseElement) {
  //       navbarCollapseElement.classList.add("show")
  //     }
  //   } else {
  //     if (navbarCollapseElement) {
  //       navbarCollapseElement.classList.remove("show")
  //     }
  //   }
  // }

  const logOut = (e: React.MouseEvent) => {
    userApi.callLogout((message: string, resp: any) => {
      if (resp) {
        toogleSideBarOnOff(false)
        userAction.logout()
        localStorage.removeItem(STORAGE)
        localStorage.removeItem(SELECTED_EVENT_STORAGE)
        localStorage.removeItem(INSIDERODEO_EVENT_STORAGE)
        history.push(`${URLS.HOME}`)
      } else {
        toast.error(message)
      }
    }, (message: string) => {
      userAction.logout()
      // toast.error(message)
    })
  }

  const goToMyAccount = () => {
    history.push(URLS.USER.PROFILE)
  }

  const closeIosPopup = (e: any) => {
    e.preventDefault()
    setIsShowIosPopup(0)
    localStorage.setItem(IS_SHOWING_ADD_TO_HOME_SCREEN_IOS, '0')
  }

  const onStatusModalClose = () => {
    setShowStatusModal(false)
  }

  const onCouponModalClose = () => {
    setShowCouponModal(false)
  }

  const onStatusModalSuccess = () => {
      if(appRedirectData) {
          setShowStatusModal(false)
          let link = appRedirectData.game_info.pwa_link
          if(link) {
              // if (userDetails && userToken) {
                  window.location.href = link + (appRedirectData?.rodeo_event_id ? `#token=${cryptr.encrypt(appRedirectData.rodeo_event_id)}&userToken=${userToken}` : "")
              // }
          }
      }  
  }

  const [socket] = useSocket(SOCKET_URL, {
    autoConnect: false,
  });

  useEffect(() => {
    socket.connect();

    socket.on(SOCKET_CHANNEL.APP_REDIRECT, (data: any) => {
      if(data.game_info.slug!=APPLICATION_GLOBAL_SLUG) {
          console.log({data})
          setShowStatusModal(true)
          setAppRedirectData(data)
      }
    });

    socket.on(SOCKET_CHANNEL.COUPON, (data: any) => {
      console.log({data})
      setCouponData(data)
      setShowCouponModal(true)
    });

    return () => {
      socket.disconnect();
    };
  }, [socket]);

  useEffect(()=> {
    if(location.pathname!="/")
    {
      let x=document.getElementById('rodeo_body')
      if(x)
      {
          x.style.backgroundColor=""
      }
    }
  },[location])

  useEffect(()=>{
    if(userToken) {
      userApi.callCheckValidToken(
        (message:any, resp:any)=>{
            console.log(1,{resp})
            if(!resp) {
            console.log(2,{resp})
            userAction.logout()
          }
        },
        (message:any, resp:any)=>{
          console.log(3,{resp})
          userAction.logout()
        }
      )
    }
  },[page])

  return (

    <section className="top-nav">
      {/*LEFT MENU*/}
      {/*Navbar*/}
      <nav id="sidebar" className="mCustomScrollbar _mCS_1 mCS-autoHide mCS_no_scrollbar" style={{ overflow: 'visible' }}>
        <div id="dismiss" onClick={() => toogleSideBarOnOff(false)}>
          <i className="fa fa-arrow-left" />
        </div>
        <div className="sidebar-header">
          <img src="/images/logo.png" alt="Screen Show" /> Screen Show
        </div>
        {
          userDetails &&
          <div className="head-user">
            {/* Welcome, {userDetails && userDetails.full_name && userDetails.full_name !== " " ? userDetails.full_name : ' User'} */}
            Welcome, {userDetails && userDetails.first_name && userDetails.last_name ? userDetails.first_name +" "+userDetails.last_name : ' User'}
          </div>
        }

        <ul className="list-unstyled components">
          
          <li>
            <Link to={URLS.HOME}>Show the screen</Link>
          </li>
          
          {
            userDetails &&
            <li>
              <Link to={URLS.USER.PROFILE}>My Profile</Link>
            </li>
          }

          <li>
            <Link to={URLS.CMS.ABOUT_US}>About Us</Link>
          </li>
          <li>
            <Link to={URLS.CMS.LEGAL}>Legal</Link>
          </li>
          {
            userDetails &&
            <li>
              <Link to="#" onClick={(e) => logOut(e)}>Logout</Link>
            </li>
          }

        </ul>
      </nav>
      <nav className="navbar navbar-light">
        <div className="container-fluid">
          <button type="button" id="sidebarCollapse" className="btn-none">
            <span className="open-nav" onClick={() => toogleSideBarOnOff(true)}>&#9776;</span>
          </button>
          {/* {
            [RODEO_PAGE_NAME.EVENT_GAME_LIST, RODEO_PAGE_NAME.EVENT_GAME_DETAILS, RODEO_PAGE_NAME.RIDER_LIST].includes(page) &&
            <div className="status-bar-wrap">
              <Marquee direction='left' loop={0} pauseOnHover={true} speed={60}>
                {
                  statusMessageSelector && statusMessageSelector.length ?
                    statusMessageSelector.map((status: any) => status.status_text).join(',') : null
                }
              </Marquee>
            </div>
          } */}
          {
            userDetails &&
            <button
              className="navbar-toggler btn-none d-inline-block ml-auto user-image"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarCollapse"
              aria-controls="navbarCollapse"
              aria-expanded="false"
              aria-label="Toggle navigation"
              // onClick={toogleProfileMenuBarOnOff}
              onClick={goToMyAccount}
            >
              <img src="/images/user.svg" alt="User" />
            </button>
          }
        </div>
      </nav>
      <div className="overlay" id="overlay" onClick={() => toogleSideBarOnOff(false)} />

      {/* IOS Scrren Add to home scrren option popup */}
      {
        detectDevice() && isShowIosPopup ?
          <>
            < div className="bottom_fixedsec">
              <div className="mid-sec">
                <h2>To add this Instant app to the home screen: tap <span className="share-x-icon" /> and then Add to Home Screen.</h2>
              </div>
              <div className="icon_div_right">
                <a href="#" onClick={(e) => closeIosPopup(e)}>
                  <svg className="svg-inline--fa fa-times-circle fa-w-16" aria-hidden="true" data-prefix="fa" data-icon="times-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg>
                    <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z" />
                  </svg>
                </a>
              </div>
            </div>
          </> : null
      }
      {
        showStatusModal && appRedirectData &&
        <StatusModal
            shouldShow={showStatusModal}
            onClose={onStatusModalClose}
            onSuccess={onStatusModalSuccess}
            message={appRedirectData?.message}
            type={2}
        />
      }
      {
        showCouponModal && couponData && couponData.image && (couponData.type==1||(couponData.type==2 && userDetails && userDetails.id)) && 
        <CouponModal
            shouldShow={showCouponModal}
            onClose={onCouponModalClose}
            image={couponData.image}
        />
      }
    </section >
  )
}

export default Sidebar
