import { API_BASE_URL, SOCKETURL } from './site_urls'

export const APPLICATION_NAME = 'ScreenShow'
export const APPLICATION_GLOBAL_SLUG = 'screen-show'
export const STORAGE = 'RODEO-APP'
export const SELECTED_EVENT_STORAGE = `${APPLICATION_NAME}-Event`

export const INSIDERODEO_EVENT_STORAGE = `EXTERNAL-EVENT-FOR-SCREENSHOW`

export const RODEPICK_NEXT_GAME_IDS = `${APPLICATION_NAME}-NEXT-GAMES`

export const IS_SHOWING_ADD_TO_HOME_SCREEN_IOS = `${APPLICATION_NAME}-IOS`

export const PER_PAGE = 10;
export const WITHOUT_PER_PAGE = 99999;

export const FILE_BASE_PATH = ''
export const APP_VERSION = process.env.REACT_APP_VERSION
export const APP_STAGE = process.env.REACT_APP_STAGE

export const DATE_FORMAT = 'MM/dd/yyyy';

export const DATE_ALL_FORMAT = {
  DATE_PICKER_FORMAT: 'MM/dd/yyyy',
  MOMENT_FORMAT: 'MM/DD/yyyy'
}

export const CHAT_DATE_TIME_FORMAT = {
  DISPLAY_DATE_WITH_TIME: 'Do MMMM, YYYY hh:mm a',
  DISPLAY_DAY_TIME: 'dddd hh:mm a'
}
export const TIME_CONFIG = {
  TIME_FORMAT: 'hh:mm a',
  TIME_INTERVALS: 5,
}


export const SOCKET_URL = SOCKETURL

export const URLS = {
  HOME: '/',
  LOGIN: '/login',
  REGISTRATION: '/registration',
  FORGOT_PASSWORD: '/forgot-password',
  FORGOT_PASSWORD_OTP: '/forgot-password-otp',
  RESET_PASSWORD: '/reset-password',
  TV_IMAGE: '/tv-leaderboard',
  USER: {
    DASHBOARD: '/dashboard',
    PROFILE: '/profile'
  },
  CMS: {
    ABOUT_US: '/cms/about-us',
    LEGAL: '/cms/legal',
    HOW_TO_PLAY: '/cms/how-to-play',
    TERM_OF_USE: '/cms/term-of-use',
  },
  EVENT: {
    LIST: '/events',
    EVENT_GAMES: '/event/:eventId',
    EVENT_GAMES_DETAILS: '/event/:eventId/:gameId',
    RIDER_LIST: '/event/:eventId/:gameId',
    LEADER_BOARD: {
      EVENT: '/result/:eventId',
      GAME: '/result/:eventId/:gameId',
    }
  },
}

export const API_URL = {
  USER: {
    LOGIN: `${API_BASE_URL}login`,
    LOGIN_WITH_OTP: `${API_BASE_URL}login-with-otp`,
    LOGOUT: `${API_BASE_URL}auth/logout`,
    DETAILS: `${API_BASE_URL}init`,
    UPDATE_PROFILE: `${API_BASE_URL}users/update`,
    CHECK_VALID_TOKEN: `${API_BASE_URL}check-valid-token`,
  },
  TV_IMAGE: {
    DETAILS: `${API_BASE_URL}rodeo-pick/leaderboard-tvimage`
  },
  EVENT: {
    // GET_EVENT_LIST: `${API_BASE_URL}rodeo-pick/rodeo-event`,
    // GET_EVENT_GAME: `${API_BASE_URL}rodeo-pick/rodeo-event/events`,
    // GET_EVENT_GAME_DETAILS: `${API_BASE_URL}rodeo-pick/rodeo-event/event-details`,
    // PICK_RIDER: `${API_BASE_URL}rodeo-pick/rodeo-event/user-pick`,
    // LEADERBOARD: `${API_BASE_URL}rodeo-pick/rodeo-event/leaderboard`,
    // SAVE_VOTE: `${API_BASE_URL}rodeo-pick/rodeo-event/save-vote`,

    // GET_UPCOMING_EVENT: `${API_BASE_URL}rodeo-race/upcoming-events`,
    // JOIN_EVENT_GAME: `${API_BASE_URL}rodeo-race/rodeo-event/join`,
    GET_GAME_SETTINGS: `${API_BASE_URL}screen-show/settings`,
    SAVE_COLOR: `${API_BASE_URL}screen-show/save-color`,
  },
  CMS: {
    GET_CMS: `${API_BASE_URL}rodeo-race/get-cms`,
  },
  COMMON: {
    GENDER_LIST: `${API_BASE_URL}genders`,
    COUNTRY_LIST: `${API_BASE_URL}country/list`,
    SECRET_QUESTION_LIST: `${API_BASE_URL}secret/questions`,
  }

}

export const ACTIONS = {
  LOADER: {
    SET_FP_STATUS: 'ACTIONS/LOADER/SET_FP_STATUS',
  },
  GLOBAL: {
    SET_LOGIN_MODAL: 'ACTIONS/GLOBAL/SET_LOGIN_MODAL',
  },
  FORGOT_PASSWORD: {
    FORGOT_PASSWORD_STEP: 'ACTIONS/FORGOT_PASSWORD/FORGOT_PASSWORD_STEP',
    FORGOT_PASSWORD_DATA: 'ACTIONS/FORGOT_PASSWORD/FORGOT_PASSWORD_DATA',
  },
  USER: {
    LOGIN: 'ACTIONS/USER/LOGIN',
    LOGIN_WITH_OTP: 'ACTIONS/USER/LOGIN_WITH_OTP',
    ME: 'ACTIONS/USER/ME',
    ONLINE_STATUS_CHANGE: 'ACTIONS/USER/ONLINE_STATUS_CHANGE',
    SOCKET_INSTANCE_CONTAINER: 'ACTIONS/USER/SOCKET_INSTANCE_CONTAINER',
    LOGOUT: 'ACTIONS/USER/LOGOUT',
    PROFILE: 'ACTIONS/USER/PROFILE',
    GUEST_USER_ID: 'ACTIONS/USER/GUEST_USER_ID',
    RESET_GUEST_USER_ID: 'ACTIONS/USER/RESET_GUEST_USER_ID',
  },
  EVENT: {
    GET_EVENT_LIST: 'ACTIONS/EVENT/GET_EVENT_LIST',
    // GET_UPCOMING_EVENT: 'ACTIONS/EVENT/GET_UPCOMING_EVENT',
    GET_EVENT_GAME: 'ACTIONS/EVENT/GET_EVENT_GAME',
    GET_EVENT_GAME_DETAILS: 'ACTIONS/EVENT/GET_EVENT_GAME_DETAILS',
    GET_STATUS_MESSAGE: 'ACTIONS/EVENT/GET_STATUS_MESSAGE',
    LEADERBOARD: 'ACTIONS/EVENT/LEADERBOARD',
    RODEO_EVENT_LOGIN_REQUIRED:  'ACTIONS/EVENT/RODEO_EVENT_LOGIN_REQUIRED',

    OPEN_WINING_MODAL: 'ACTIONS/EVENT/OPEN_WINING_MODAL',
    SET_PICK_ID: 'ACTIONS/EVENT/SET_PICK_ID',
    RESET_PICK_ID: 'ACTIONS/EVENT/RESET_PICK_ID',

    //SOCKET
    GET_SOCKET_START_DATA_AND_PUSH_TO_GAME_LIST: 'ACTIONS/EVENT/GET_SOCKET_START_DATA_AND_PUSH_TO_GAME_LIST',
    GET_SOCKET_START_DATA_AND_PUSH_TO_GAME_DETAILS: 'ACTIONS/EVENT/GET_SOCKET_START_DATA_AND_PUSH_TO_GAME_DETAILS',
    GET_SOCKET_STATUS_MESSAGE_AND_PUSH_TO_STATUS_LIST: 'ACTIONS/EVENT/GET_SOCKET_STATUS_MESSAGE_AND_PUSH_TO_STATUS_LIST',
    GET_SOCKET_RIDER_WITH_VOTE_DETAILS: 'ACTIONS/EVENT/GET_SOCKET_RIDER_WITH_VOTE_DETAILS',
    GET_WINING_SOCKET_DATA_AND_PUSH_TO_WINING_DETAILS: 'ACTIONS/EVENT/GET_WINING_SOCKET_DATA',

    GET_EVENT_LIST_GLOWING_SOCKET: 'ACTIONS/EVENT/GET_EVENT_LIST_GLOWING_SOCKET',

    //Route change EVENT
    NEXT_EVENT_FROM_ROUTE: 'ACTIONS/EVENT/NEXT_EVENT_FROM_ROUTE',
  },
  TV_IMAGE: {
    DETAILS: 'ACTIONS/TV_IMAGE/DETAILS'
  }
}

export const SAGA_ACTIONS = {
  USER: {
    LOGIN: 'SAGA_ACTIONS/USER/LOGIN',
    LOGIN_WITH_OTP: 'SAGA_ACTIONS/USER/LOGIN_WITH_OTP',
    LOGOUT: 'SAGA_ACTIONS/USER/LOGOUT',
    DETAILS: 'SAGA_ACTIONS/USER/DETAILS',
    UPDATE_PROFILE: 'SAGA_ACTIONS/USER/UPDATE_PROFILE',
    CHECK_VALID_TOKEN: 'SAGA_ACTIONS/USER/CHECK_VALID_TOKEN',
  },
  EVENT: {
    // GET_EVENT_LIST: 'SAGA_ACTIONS/EVENT/GET_EVENT_LIST',
    // GET_EVENT_GAME: 'SAGA_ACTIONS/EVENT/GET_EVENT_GAME',
    // GET_EVENT_GAME_DETAILS: 'SAGA_ACTIONS/EVENT/GET_EVENT_GAME_DETAILS',
    // PICK_RIDER: 'SAGA_ACTIONS/EVENT/PICK_RIDER',
    // LEADERBOARD: 'SAGA_ACTIONS/EVENT/LEADERBOARD',
    // SAVE_VOTE: 'SAGA_ACTIONS/EVENT/SAVE_VOTE',

    // GET_UPCOMING_EVENT: 'SAGA_ACTIONS/EVENT/GET_UPCOMING_EVENT',
    // JOIN_EVENT_GAME: 'SAGA_ACTIONS/EVENT/JOIN_EVENT_GAME',
    GET_GAME_SETTINGS: 'SAGA_ACTIONS/EVENT/GET_GAME_SETTINGS',
    SAVE_COLOR: 'SAGA_ACTIONS/EVENT/SAVE_COLOR',
  },
  TV_IMAGE: {
    DETAILS: 'SAGA_ACTIONS/TV_IMAGE/DETAILS',
  },
  CMS: {
    GET_CMS: 'SAGA_ACTIONS/COMMON/GET_CMS',
  },
  COMMON: {
    GENDER_LIST: 'SAGA_ACTIONS/COMMON/GENDER_LIST',
    COUNTRY_LIST: 'SAGA_ACTIONS/COMMON/COUNTRY_LIST',
    SECRET_QUESTION_LIST: 'SAGA_ACTIONS/COMMON/SECRET_QUESTION_LIST'
  }
}

export * from './site_statics'
export * from './site_urls'
export * from './functions'
export * from './canvasUtils'
export * from './card_utils'